import Component from '../models/Component';

export default class SliderModali extends Component {
    constructor(element, context) {
        super(element, context);

        this.currentIndex = 1;
        this.totalSlides = document.querySelector('.wrapper-buttons-modal').childElementCount; 

        for (let i = 1; i <= this.totalSlides; i++) {
            document.getElementById('button-' + i).addEventListener('click', () => {
                this.showSlide(i);
            });

            document.getElementById('close-' + i).addEventListener('click', () => {
                this.hideSlide(i);
            });
        }

        document.getElementById('prev').addEventListener('click', () => {
            this.showPreviousSlide();
        });

        document.getElementById('next').addEventListener('click', () => {
            this.showNextSlide();
        });

        this.updateButtons();
    }

    showSlide(index) {
        document.getElementById('wrapper-modali').classList.remove('hidden');
        document.getElementById('modale-' + index).classList.remove('opacity-0');
        document.getElementById('modale-' + index).classList.remove('pointer-events-none');
        document.body.classList.add('no-scroll');
        this.currentIndex = index;
        this.updateButtons();
    }

    hideSlide(index) {
        document.getElementById('wrapper-modali').classList.add('hidden');
        document.getElementById('modale-' + index).classList.add('opacity-0');
        document.getElementById('modale-' + index).classList.add('pointer-events-none');
        document.body.classList.remove('no-scroll');
    }

    showPreviousSlide() {
        if (this.currentIndex > 1) {
            this.hideSlide(this.currentIndex);
            this.currentIndex--;
            this.showSlide(this.currentIndex);
        }
    }

    showNextSlide() {
        if (this.currentIndex < this.totalSlides) {
            this.hideSlide(this.currentIndex);
            this.currentIndex++;
            this.showSlide(this.currentIndex);
        }
    }

    updateButtons() {
        const prevButton = document.getElementById('prev');
        const nextButton = document.getElementById('next');

        if (this.currentIndex === 1 ) {
            prevButton.classList.add('disabled');
            prevButton.style.opacity = '0.4';
            prevButton.classList.add('pointer-events-none');
        } else {
            prevButton.classList.remove('disabled');
            prevButton.style.opacity = '1';
            prevButton.classList.remove('pointer-events-none');
        }

        if (this.currentIndex === this.totalSlides ) {
            nextButton.classList.add('disabled');
            nextButton.style.opacity = '0.4';
            nextButton.classList.add('pointer-events-none');
        } else {
            nextButton.classList.remove('disabled');
            nextButton.style.opacity = '1';
            nextButton.classList.remove('pointer-events-none');
        }
    }
}
