import Registry from "./models/Registry";
import MainMenu from "./components/MainMenu";
import Slider from "./components/Slider";
import './components/Fadein';
import Filter from "./components/Filter";
import Accordion from "./components/Accordion"
import SliderTitolo from "./components/SliderTitolo";
import SliderImages from './components/SliderImages'
import SliderHomepage from "./components/SliderHomepage";
import ChangeBg from "./components/ChangeBg";
import TimeLine from "./components/TimeLine";
import SearchPopup from "./components/SearchPopup";
import Separatore from "./components/Separatore";
import TextReveal from './components/TextReveal';
import Line from './components/Line';
import ScrubText from './components/ScrubText';
import Footer from './components/Footer';
import SliderAnni from "./components/SliderAnni";
import InjectLink from './components/InjectLink';
import SliderNews from "./components/SliderNews";
import ScrollSmootherPage from "./components/ScrollSmootherPage";
import ArrowHeroHome from "./components/ArrowHeroHome";
import FancyBox from "./components/FancyBox";
import FullContainerVideo from "./components/FullContainerVideo";
import SliderModali from "./components/SliderModali";
import CounterAnimation from "./components/CounterAnimation";
import ImageUp from "./components/ImageUp";
import ZoomImage from './components/ZoomImage';
import TrenoSliderModali from "./components/TrenoSliderModali";
import SliderClienti from './components/SliderClienti';
import Cursor from "./components/Cursor";
import Cta from "./components/Cta";
import Modale from "./components/Modale";
import ScrollToCandidatura from './components/ScrollToCandidatura';

class App {
  constructor(context) {
    this.registry = new Registry();
    this.registerComponents();
    this.initController(context);
    this.patchIos();
  }

  initController(contextData) {
    const Context = this.registry.getContext(contextData);
    new Context(contextData);
  }

  registerComponents() {
    this.registry
      .addComponent('MainMenu', MainMenu)
      .addComponent('Slider', Slider)
      .addComponent('Filter', Filter)
      .addComponent('Accordion', Accordion)
      .addComponent('SliderTitolo', SliderTitolo)
      .addComponent('SliderHomepage', SliderHomepage)
      .addComponent('ChangeBg', ChangeBg)
      .addComponent('TimeLine', TimeLine)
      .addComponent('SearchPopup', SearchPopup)
      .addComponent('Separatore', Separatore)
      .addComponent('ScrubText', ScrubText)
      .addComponent('Line', Line)
      .addComponent('TextReveal', TextReveal)
      .addComponent('Footer', Footer)
      .addComponent('InjectLink', InjectLink)
      .addComponent('SliderImages', SliderImages)
      .addComponent('SliderAnni', SliderAnni)
      .addComponent('SliderNews', SliderNews)
      .addComponent('ScrollSmootherPage', ScrollSmootherPage)
      .addComponent('ArrowHeroHome', ArrowHeroHome)
      .addComponent('FancyBox', FancyBox)
      .addComponent('FullContainerVideo', FullContainerVideo)
      .addComponent('SliderModali', SliderModali)
      .addComponent('CounterAnimation', CounterAnimation)
      .addComponent('ImageUp', ImageUp)
      .addComponent('TrenoSliderModali', TrenoSliderModali)
      .addComponent('SliderClienti', SliderClienti)
      .addComponent('Cursor', Cursor)
      .addComponent('Cta', Cta)
      .addComponent('ZoomImage', ZoomImage)
      .addComponent('Modale', Modale)
      .addComponent('ScrollToCandidatura', ScrollToCandidatura)
    ;
  }

  patchIos() {
    window.addEventListener('resize', () => this.appHeight())
    this.appHeight()
  }

  appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }
}

window.AppInit = function(context) {
    new App(context);
};