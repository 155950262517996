import { gsapSingleton, scrollSmootherSingleton, scrollToPluginSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTo = scrollToPluginSingleton();
import $ from 'jquery';
import Component from '../models/Component';

export default class ScrollToCandidatura extends Component {
    constructor(element, context) {
        super(element, context);

        const link =this.element.find('.first-link-candidatura');
            
        link.on( "click", function() {
            console.log(link);

            gsap.to(window, {
                duration:1,
                ease: 'power3.out',
                scrollTo: {
                    y: '#form-candidatura',
                    offsetY: 100
                }
            })

        })

        

    }   

}
