
import $ from 'jquery';
import Component from '../models/Component';

export default class Modale extends Component  {
  constructor(element, context) {
    super(element, context);

    const ctaModale = this.element;
    const modale = $('#modale');
    const closeIcon = $('#close-icon')

    ctaModale.on( "click", function() {
        $('body').addClass('no-scroll');
        modale.removeClass("hidden")
        modale.addClass("block")
    } );

    closeIcon.on( "click", function() {
        $('body').removeClass('no-scroll');
        modale.addClass("hidden");
        modale.removeClass("block");
    } );

  }
}
