import { gsapSingleton, splitTextSingleton } from "../gsap";
const gsap = gsapSingleton();
const SplitText = splitTextSingleton()
import $ from 'jquery';
import Component from '../models/Component';


export default class TextReveal extends Component {
    constructor(element, context) {
        super(element, context);
    
        const testo = this.element.find('.text-reveal');

        let mySplitText = new SplitText(testo, { type: "lines" });
        let lines = mySplitText.lines;

        // Wrap each line in a container with overflow hidden
        lines.forEach(line => {
            const wrapper = document.createElement('div');
            wrapper.style.overflow = 'hidden';
            line.parentNode.insertBefore(wrapper, line);
            wrapper.appendChild(line);
        });

        gsap.from(lines, {
            yPercent: 100,
            autoAlpha: 0,
            duration: 1, 
            stagger: 0.1,
            ease: 'power2.out'
        });
        
      
    }
}