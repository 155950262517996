import $ from 'jquery';
import Component from "../models/Component";

export default class Cursor extends Component {
  constructor(element, context) {
    super(element, context);

    const $c = this.element;
    const $SliderSection = $('.slider');

    $(() => {
      
    
      $SliderSection.hover(
        () => $c.addClass('slider'),
        () => $c.removeClass('slider')
      );
    
      $(window).on('mousemove', ({ clientX: x, clientY: y }) => {
        $c.css({ transform: `translate(${x}px, ${y}px)` });
      });
    });
  }
}

