import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery'

export default class SliderHomepage extends SwiperSlider {
  constructor(element, context) {
    super(element, context);


    $(() => {
      this.options = {
        slidesPerView: 1.1,
        spaceBetween: 24,
        breakpoints: {
          768: {
            slidesPerView: 1.5,
            spaceBetween: 24,
          },
          1024: {
            slidesPerView: 1.7,
            spaceBetween: 32,
          },
          1280: {
            slidesPerView: 2.2,
            spaceBetween: 50,
          },
          1600: {
            slidesPerView: 3.2,
            spaceBetween: 50,
          },
        },
      };
      this.init();
    });
  }
}
