import $ from 'jquery';
import Component from "../models/Component";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class ChangeBg extends Component {
  constructor(element, context) {
    super(element, context);

    if (!this.element.hasClass('light')) {
      let trigger = this.element.find('#TriggerChangeBg');
      let changeOpacity = this.element.find('#ChangeOpacity');
  
      $(() => {
        
        gsap.fromTo(
          'body',
          {
            backgroundColor: '#00285E'
          },
          {
            backgroundColor: '#fff',
            duration: 0.2,
            scrollTrigger: {
              trigger: trigger,
              toggleActions: 'restart none reverse none',
              start: '30% top',
              end: '30% top',
            }
          }
        );
  
        if (changeOpacity) {
          gsap.fromTo(
            changeOpacity,
            {
              opacity: 1,
            },
            {
              opacity: 0,
              duration: 0.6,
              scrollTrigger: {
                trigger: trigger,
                toggleActions: 'restart none reverse none',
                start: '30% top',
                end: '30% top',
              }
            }
          );
        }
      });
    }
  }
}

