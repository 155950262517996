import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
import $ from 'jquery';
import Component from '../models/Component';
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

export default class ZoomImage extends Component {
    constructor(element, context) {
    super(element, context);

        $(() => {
            const image = this.element.find('img');

            
            gsap.fromTo( image,

                { scale: 1 },
                {
                    scale: 1.2,
                    scrollTrigger: {
                        trigger: image,
                        start: 'top-=200 top',
                        end: 'bottom top',
                        scrub: 1,
                    },
                }
            )
            
        });

    }
}
