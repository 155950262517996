import { gsapSingleton, scrollSmootherSingleton, scrollToPluginSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTo = scrollToPluginSingleton();
import $ from 'jquery';
import Component from '../models/Component';

export default class ArrowHeroHome extends Component {
    constructor(element, context) {
        super(element, context);

        element.addEventListener("click", function() {


            gsap.to(window, {
                duration:1,
                ease: 'power3.out',
                scrollTo: {
                    y: '#scroll-to',
                    offsetY: 100
                }
            })

        })
        

    }   

}
