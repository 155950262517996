import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();
import $ from 'jquery';
import Component from '../models/Component';


export default class ScrubText extends Component {
    constructor(element, context) {
        super(element, context);

        $( document ).ready(function() {
        
            let anim = gsap.to(element, {
                xPercent: -100,
            })
        
            
            ScrollTrigger.create({
            trigger: element,
            scrub: 2,
            animation: anim
            });
        });
    }
}
