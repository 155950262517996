import $ from 'jquery';
import Component from "../models/Component";

export default class SearchPopup extends Component {
 constructor(element, context) {
  super(element, context);
  $(() => {
   this.init();
  });
 }

 init() {

  $(this.element).click(function (event) {
   event.stopPropagation();
   $('#section-search-desktop').fadeToggle();
  });

  $('.close-element-search ').click(function (event) {
   event.stopPropagation();
   $('#section-search-desktop').fadeToggle();
  });
 }
}