import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery'

export default class SliderNews extends SwiperSlider {
  constructor(element, context) {
    super(element, context);
    $(() => {
      this.options = {
       navigation: {
        nextEl: ".swiper-button-next-news",
        prevEl: ".swiper-button-prev-news",
       },
       scrollbar: {
        el: ".swiper-scrollbar",
      },
          spaceBetween: 16,
          slidesPerView: 1.05,
          breakpoints: {
            1024: {
              slidesPerView: 2.2,
              spaceBetween: 30,
            },
          },
          loop: false,
        };

        $(() => {
          this.init()
        })
    });
  }
}