import $ from 'jquery';
import Component from "../models/Component";


export default class Footer extends Component {
 constructor(element, context) {
  super(element, context);
  $(() => {
   this.init();
  });
 }

 init() {
  let icon = this.element.find('.icon-footer');

  $(icon).on('click', function () {
   let liparent = $(this).parent('li');
   $(liparent).toggleClass('is-active');
   $(this).toggleClass('is-active');

  })
 }
}