import $ from 'jquery';
import Component from '../models/Component';
import { gsapSingleton, motionPathPluginSingleton } from "../gsap";
const gsap = gsapSingleton();
const MotionPathPlugin = motionPathPluginSingleton();

gsap.registerPlugin(MotionPathPlugin);

export default class TrenoSliderModali extends Component {

    constructor(element, context) {
        super(element, context);     
        gsap.to("#treno-modali", {
            motionPath: {
                path: "#rotaia-modali",
                autoRotate: true,
                align: "#rotaia-modali",
                alignOrigin: [0.5, 0.5],
            },
            scrollTrigger: {
                trigger: element,
                start: "top 30%",
                end: "center top",
                scrub: 3,
            },
            
        })
    }
}