import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();
import $ from 'jquery';
import Component from '../models/Component';


export default class Line extends Component {
    constructor(element, context) {
        super(element, context);

        $( document ).ready(function() {
        
            let anim = gsap.fromTo(element, 
                {
                    width: 0
                },
                {
                    width: '100%',
                    duration: 1,
                    ease: 'power1.out'
                }
            )
        
            
            ScrollTrigger.create({
            trigger: element,
            start: 'center bottom',
            animation: anim,
            toggleActions: 'restart none none none',
            });
        });
    }
}