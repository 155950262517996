import $ from 'jquery';
import Component from '../models/Component';

export default class FullContainerVideo extends Component {
  constructor(element, context) {
    super(element, context);

    $(() => {
      let play = this.element.find('.play-button');
      let pause = this.element.find('.pause-button');
      let element = this.element.find('#player');
      let id = element.attr('data-id');

      let player;
       player = new YT.Player('player', {
       videoId: id,
       height: window.innerWidth > 768 ? '720' : '350',
       width: '1280',
       playerVars: {
         'html5': 1,
         'controls': 0,
         'modestbranding': 1,
         'autoplay': 0,
         'rel': 0,
         'showinfo': 0,
         'listType': 'WEGHGroup',
       },
       events: {
         'onStateChange': onPlayerStateChange
       }
     });

     function onPlayerStateChange (event) {
      if (event.data == YT.PlayerState.ENDED) {
       $(play).show();
       $(pause).hide().removeClass('flex');
      }
    }

     $(play).on('click', function() {
      player.playVideo();
      $(play).hide();
      $(pause).show().addClass('flex');
     })

     $(pause).on('click', function() {
      player.pauseVideo();
      $(play).show();
      $(pause).hide().removeClass('flex');
     })

    });
  }
}
