import $ from 'jquery';
import Component from '../models/Component';
import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

export default class TimeLine extends Component {
  constructor(element, context) {
    super(element, context);

    // Controlla se la larghezza dello schermo è almeno 1024px
    const mediaQuery = window.matchMedia("(min-width: 1024px)");

    // Funzione per inizializzare il codice se la condizione è soddisfatta
    const initializeTimeline = () => {
      let rotaia = this.element.find('.vertical-dotted-line');
      let altezzaRotaia = rotaia.height();
      let treno = this.element.find('.vertical-line');

      gsap.to(
        treno,
        {
          y: altezzaRotaia,
          ease: 'none',
          scrollTrigger: {
            trigger: rotaia,
            start: 'top center',
            end: 'bottom center',
            scrub: true,
          },
        }
      );

      gsap.to(
        element,
        {
          backgroundColor: '#002857',
          ease: 'none',
          duration: '0.3',
          scrollTrigger: {
            trigger: rotaia,
            toggleActions: 'play none reverse none',
            start: '70% bottom',
            end: '70% bottom',
            onEnter: () => {
              $(element).addClass('dark');
            },
            onLeaveBack: () => {
              $(element).removeClass('dark');
            },
          },
        }
      );
    };

    // Inizializza il codice se la condizione è soddisfatta
    if (mediaQuery.matches) {
      $(document).ready(() => {
        initializeTimeline();
      });
    }

    // Event listener per eventuali cambiamenti nella dimensione della finestra
    mediaQuery.addListener((e) => {
      if (e.matches) {
        $(document).ready(() => {
          initializeTimeline();
        });
      }
    });
  }
}
