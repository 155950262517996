import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
import $ from 'jquery';
const ScrollTrigger = scrollTriggerSingleton();
import Component from '../models/Component';


export default class Cta extends Component {
    constructor(element, context) {
        super(element, context);
        

        let hoverBackground = this.element.find('.hover-animation');
        let textWrapper = this.element.find('.text-wrapper');
        let iconWrapper = this.element.find('.icon-wrapper');

        this.element.on('mouseenter', function() {
            gsap.to(hoverBackground, {
                yPercent: -100,
                duration: .5,
                ease: 'power2.inOut'
            })

            gsap.to(textWrapper, {
                yPercent: -100,
                duration: .5,
                ease: 'power2.inOut'
            })

            gsap.to(iconWrapper, {
                yPercent: -150,
                duration: .5,
                ease: 'power2.inOut'
            })
        });

        this.element.on('mouseleave', function() {
            gsap.to(hoverBackground, {
                yPercent: -200,
                duration: .5,
                ease: 'power2.inOut',
                onComplete: function() {
                    // Ripristina la posizione iniziale dell'elemento animato
                    gsap.set(hoverBackground, { yPercent: 0 });
                }
            })

            gsap.to(textWrapper, {
                yPercent: 0,
                duration: .5,
                ease: 'power2.inOut',
            })

            gsap.to(iconWrapper, {
                yPercent: 0,
                duration: .5,
                ease: 'power2.inOut',
            })
        })
        
        
      
    }
}