import { gsapSingleton, scrollSmootherSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollSmoother = scrollSmootherSingleton()
import $ from 'jquery';
import Component from '../models/Component';


export default class ScrollSmootherPage extends Component {
    constructor(element, context) {
        super(element, context);
    
        let smoother = ScrollSmoother.create({
            smooth: 2,
            effects: true,
        });
        
      
    }
}