import $ from 'jquery';
import Component from '../models/Component';

export default class TimeLine extends Component {
  constructor(element, context) {
    super(element, context);

    $(() => {
      let testo = this.element.find('.descrizione-testimonianza');
      let url = this.element.data('url');
      let link = $(`<a href="${url}" class="link-testimonianza"></a>`);
      testo.append(link);
    });
  }
}
