import { Fancybox } from "@fancyapps/ui";


import Component from "../models/Component";

export default class FancyBox extends Component {
    constructor(element, context) {
      super(element, context);
   
      Fancybox.bind("[data-fancybox]", {
        // Your custom options
      });      
      
    }
  }
  