import $ from 'jquery';
import Component from "../models/Component";
import {
  $Doc,
  $Win
} from "../utils/jQueryGlobals";
import { gsapSingleton, customEaseSingleton } from "../gsap";
const gsap = gsapSingleton();
const CustomEase = customEaseSingleton();

gsap.registerPlugin(CustomEase);


export default class MainMenu extends Component {
  constructor(element, context) {
    super(element, context);
    $(() => {
      this.init();
    });
  }
  

  moveheight() {
    var lastScrollTop = 0;
    var scrollTimeout;
    
    $(window).scroll(function () {
        var scrolltop = $(this).scrollTop();
        var headerHeight = $('header').outerHeight();

        if (window.innerWidth > 1024) {
          if (!$('body').hasClass('open-menu')) {
              if (scrolltop < lastScrollTop) {
                  $('header').removeClass('move-height');
              } else {
                  $('header').addClass('move-height');
              }
      
              if (scrolltop == 0) {
                  setTimeout(function () {
                      $('header').removeClass('move-height');
                  }, 250);
              }
          }
        }
    
        // Cancella il timeout precedente
        clearTimeout(scrollTimeout);
    
        // Imposta un nuovo timeout per rimuovere la classe dopo 2 secondi
        scrollTimeout = setTimeout(function () {
            $('header').removeClass('move-height');
        }, 500);
    
        // Controlla se lo scroll ha superato l'altezza dell'header
        if (scrolltop > headerHeight) {
            $('header').addClass('always-white');
        } else {
            $('header').removeClass('always-white');
        }
    
        lastScrollTop = scrolltop;
    });
    
  }

 

  init() {
    let FirstItemswithSubmenu = this.element.find('.menu-items > .menu-item-has-children');
    let SecondItemswithSubmenu = this.element.find('.menu-items .sub-menu-0 .menu-item-has-children');
    let secondItems = this.element.find('.menu-items .sub-menu-0 > .submenu-items > .menu-item');
    CustomEase.create("in-out", "0.42,0,0.58,1")
    this.moveheight();
    if (window.innerWidth > 1024) {

      $(secondItems)
        .on( "mouseenter", function() {
          let secondItem = $(this);
          let secondItemImage = secondItem.find('.submenu-image-second')

          gsap.fromTo( secondItemImage,
            { 
              left: '52%',
              width: 0
            },
            {
              width: '48%',
              
              duration: 0.4
            }
          )

        } )
        .on( "mouseleave", function() {
          let secondItem = $(this);
          let secondItemImage = secondItem.find('.submenu-image-second')
      
          gsap.fromTo( secondItemImage,
            { 
              right: 0,
              left: '52%',
            },
            {
              left: '100%',
              width: 0,
              
              duration: 0.4
            }
          )
          
        } );

      $(FirstItemswithSubmenu).hover(function () {
        let item = $(this).find('a');
        let subMenuitem = $(this).find('.sub-menu-0');
        // $('html, body').toggleClass('open-menu no-scroll');
        $('header').toggleClass('hovered');
        if ($(subMenuitem).hasClass('active')) {
          $(subMenuitem).removeClass('active');
          $(item).removeClass('active');
        } else {
          $('.active').removeClass('active');
          $(subMenuitem).addClass('active');
          $(item).addClass('active-submenu');
        }
      })

      $(SecondItemswithSubmenu).hover(function () {
        let item = $(this).find('a');
        let subMenuitem = $(this).find('.sub-menu-1');
        if ($(subMenuitem).hasClass('active-submenu')) {
          if (!subMenuitem.get(0).matches(':hover')) {
            $(subMenuitem).removeClass('active-submenu');
            $(item).removeClass('active-submenu');
          }
        } else {
          $('.active-submenu').removeClass('active-submenu');
          $(subMenuitem).addClass('active-submenu');
          $(item).addClass('active-submenu');
        }
      })

    } else {

      $('.menu-items > .menu-item-has-children > a').click(function (event) {
        event.stopPropagation();
        const li = $(this).parent();
        const subMenuitem = $(this).siblings('.sub-menu-0');
        const icon = $(this).siblings('.icon')

        // Chiudi tutti gli altri sottomenu di primo livello e rimuovi le classi attive
        $('.menu-items > .menu-item-has-children').not(li).removeClass('active-mobile');
        $('.menu-items > .menu-item-has-children > .icon').not(this).removeClass('active-icon');
        $('.menu-items > .menu-item-has-children > .sub-menu-0').not(subMenuitem).each(function() {
          const otherMenu = $(this);
          if (otherMenu.hasClass('active')) {
            gsap.to(otherMenu, { height: 0, duration: 0.5, ease: 'power2.out' });
            otherMenu.removeClass('active');
          }
        });

        if ($(subMenuitem).hasClass('active')) {
          // Se è attivo, lo chiudiamo
          gsap.to(subMenuitem, { height: 0, duration: 0.5, ease: 'power2.out' });
          $(li).removeClass('active-mobile');
          $(icon).removeClass('active-icon');
          $(subMenuitem).removeClass('active');
        } else {
          // Se è chiuso, lo apriamo
          $(li).addClass('active-mobile');
          $(icon).addClass('active-icon');
          $(subMenuitem).addClass('active');
          gsap.set(subMenuitem, { height: 'auto' }); // Imposta l'altezza auto per ottenere quella reale
          const height = subMenuitem.outerHeight(); // Ottieni l'altezza
          gsap.fromTo(subMenuitem, { height: 0 }, { height: height, duration: 0.5, ease: 'power2.out' });
        }

      });

      $('.menu-items .sub-menu-0 .menu-item-has-children .icon').click(function (event) {
        event.stopPropagation();

        const li = $(this).closest('li');
        const subMenuitem = $(li).find('.sub-menu-1').first();
        const parentSubMenu = $(li).closest('.sub-menu-0');
        let parentHeight = parentSubMenu.outerHeight();
        let height = subMenuitem.outerHeight();

        if ($(subMenuitem).hasClass('active-submenu')) {
            // Se è attivo, lo chiudiamo
            parentHeight = parentHeight - height;
            gsap.to(parentSubMenu, { height: parentHeight, duration: 0.5, ease: 'power2.out' });
            gsap.to(subMenuitem, { height: 0, duration: 0.5, ease: 'power2.out' });
            $(li).removeClass('active-mobile');
            $(this).removeClass('active-icon');
            $(subMenuitem).removeClass('active-submenu');
        } else {
            // Se è chiuso, lo apriamo
            $(li).addClass('active-mobile');
            $(this).addClass('active-icon');
            $(subMenuitem).addClass('active-submenu');
            gsap.set(subMenuitem, { height: 'auto' });
            height = subMenuitem.outerHeight();
            parentHeight = parentHeight + height;
            gsap.fromTo(subMenuitem, { height: 0 }, { height: height, duration: 0.5, ease: 'power2.out' });
            gsap.to(parentSubMenu, { height: parentHeight, duration: 0.5, ease: 'power2.out' });
        }
      });


      $('.menu-icon').on('click', function () {
        $('body').toggleClass('overflow-y-hidden')
        $(this).toggleClass('is-active');
        $('header').toggleClass('hovered');
        $('header').toggleClass('header-active');
        $('.wrapper-items-mobile').toggleClass('wrapper-active');
      })

    }


  }
}