import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
import $ from 'jquery';
import Component from '../models/Component';
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

export default class ImageUp extends Component {
    constructor(element, context) {
    super(element, context);

        $(() => {
            const image = this.element.find('img');

            gsap.from(image, {
                yPercent: -100,
                duration: 1,
            });
            gsap.from(element, {
                yPercent: 100,
                duration: 1,
            });
      
        });
    }
}
