import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery';

export default class SliderImages extends SwiperSlider {
  constructor(element, context) {
    super(element, context);

    this.options = {
        pagination: {
            el: ".swiper-pagination",
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        spaceBetween: 20,
        allowTouchMove: true,
        centeredSlides: false,
        slidesPerView: 1,
        loop: true,
        speed: 500,
        autoplay: {
            delay: 5000,
        }
    };

    $(() => {
      this.init();
    });
  }
}
