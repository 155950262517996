import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery'

export default class Slider extends SwiperSlider {
  constructor(element, context) {
    super(element, context);
    $(() => {
      const body = $('body');
      const direction = this.element.data('direction');
      const breakpoints = this.element.data('breakpoints');
      let slidesPerView;
      const spaceBetween = this.element.data('spacebetween');
      slidesPerView = this.element.data('slideperview');
      const speed = this.element.data('speed') ? this.element.data('speed') : 3000;
      
      this.options = {
          spaceBetween: 16,
          breakpoints: {
            768: {
            spaceBetween: 50,
            },
            1024: {
              slidesPerView: slidesPerView,
              spaceBetween: 80,
            },
          },
          centeredSlides: true,
          loop: true,
          speed:  speed,
          allowTouchMove: true,
          autoplay: {
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: direction,
          },
/*           on: {
            init: () => {
              setTimeout(() => {
                this.getSwiper().autoplay.start();
              }, 50)
            }
          }, */
        };
        if (spaceBetween) {
          this.options.spaceBetween = 0
        }
        if (breakpoints) {
          this.options.breakpoints = breakpoints;
        };

        $(() => {
          this.init()
        })
    });
  }
}