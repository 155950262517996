import { CountUp } from "countup.js";
import $ from 'jquery';
import Component from '../models/Component';

export default class CounterAnimation extends Component {
    constructor(element, context) {
        super(element, context);
        const counters = this.element.find('.counter-dato');
        counters.each(function() {
            let count = $(this).get(0);
            const target = parseInt(count.getAttribute('data-target'), 10);
            const countUp = new CountUp(count, target, { 
                enableScrollSpy: true, 
                separator: '.'  // Imposta il separatore delle migliaia a "."
            });
            countUp.start();
        });
    }
}