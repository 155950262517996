import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { CustomEase } from "gsap/CustomEase";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText, ScrollToPlugin, MotionPathPlugin, CustomEase);

export function gsapSingleton() {
 return gsap;
}

export function scrollTriggerSingleton() {
  return ScrollTrigger;
}

export function splitTextSingleton() {
  return SplitText;
}

export function scrollSmootherSingleton() {
  return ScrollSmoother;
}

export function scrollToPluginSingleton() {
  return ScrollToPlugin;
}

export function motionPathPluginSingleton() {
  return MotionPathPlugin;
}

export function customEaseSingleton() {
  return CustomEase;
}