import $ from 'jquery';
import Component from '../models/Component';
import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

export default class TimeLine extends Component {
  constructor(element, context) {
    super(element, context);

    $(() => {

      gsap.to(
        element,
        {
            height: '200',
            scrollTrigger: {
                trigger: element,
                start: 'start 70%',
                end: 'bottom start',
                scrub: true,
                markers: true,
            }
        }
      );
    });
  }
}
