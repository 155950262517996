import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery';

export default class SliderAnni extends SwiperSlider {
  constructor(element, context) {
    super(element, context);

    this.options = {

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            
            1024: {
                slidesPerView: 1.3,
            },
          },
        allowTouchMove: true,
        centeredSlides: false,
        slidesPerView: 1,
        spaceBetween: 100,
        loop: false,
        speed: 500,

    };

    $(() => {
      this.init();
    });
  }
}
